import React from 'react';

const RulesAndGuidelines = () => (
  <div className="text-pursuit-gray">
    <p className="">
      Congratulations on completing the training and assessment process. Thanks for sticking with it and making it
      through to the end. We know this process works, as we've had previous volunteers successfully identify underage
      victims being advertised online. Their OSINT skills and desire had lifesaving impacts on a child's life.
    </p>

    <br />

    <p>
      The next steps in this Project 1591® volunteer process, is to provide you with some basic start points and
      guidance as to how you can help Guardian Group in our effort to continue to support United States law enforcement
      by identifying underage victims of sex trafficking. The following instructions are important to follow. Please ask
      if you have questions or don't understand something.
    </p>

    <br />

    <p className="font-semibold">Requirements:</p>

    <ol className="pl-6 list-decimal">
      <li className="mb-2">
        All leads must be derived from the surface web, <span className="underline">NOT</span> the dark or deep web
        locations.
      </li>

      <li className="mb-2">
        All information must be derived through <span className="underline">passive</span> OSINT collection methods,
        using only Publicly Available Information (PAI). If it's not PAI and can't be sourced as such, it will not be
        corroborated and passed along to the appropriate authorities.
      </li>

      <li className="mb-2">
        Do not source information that is only gained through paid access. If you discover it through paid access and
        can then reverse engineer it to source the PAI source, then that's what should be submitted as a source.
      </li>

      <li className="mb-2">
        Escort Sites/Forums:
        <ol className="pl-6 list-alpha">
          <li className="mb-2">
            You must start your research with one of these sites. None of them will list a victim under the age of 18,
            so this is where you must use your skills in identifying them in true name and age, to determine it as a
            valid lead. Most underage victims are listed between 18-25 years old on these sites.
          </li>

          <li className="mb-2">
            If you believe you've identified an originating escort ad site in an area you want to look at, but it's not
            in our approved list below, contact us and we'll approve/disapprove it as a valid start point. We're looking
            for sites that contain original sources, not aggregators. We have one exception listed below. We recommend
            you copy the sites listed and paste them into your virtual machine, so you don't risk compromising your host
            machine.
          </li>

          <li className="mb-2">
            Of note, we recommend you read this page before hitting any sites. It will help you understand how to spot a
            fake ad [
            <a
              href="https://skipthegames.com/articles/about-escorts/how-to-identify-fake-escort-ads-and-avoid-scams"
              className="underline text-guardian-blue"
            >
              link
            </a>
            ]. The stuff highlighted here, is applicable to almost all other sites, not just SkipTheGames.
          </li>

          <li className="mb-2">
            Sites:
            <ol className="pl-6 list-roman">
              <li className="mb-2">
                SkipTheGames: [
                <a href="https://skipthegames.com" className="underline text-guardian-blue">
                  https://skipthegames.com
                </a>
                ]
              </li>

              <li className="mb-2">
                Megapersonals: [
                <a href="https://megapersonals.eu" className="underline text-guardian-blue">
                  https://megapersonals.eu
                </a>
                ]
              </li>

              <li className="mb-2">
                PrivateDelights: [
                <a href="https://privatedelights.ch" className="underline text-guardian-blue">
                  https://privatedelights.ch
                </a>
                ]
              </li>

              <li className="mb-2">
                KittyAds: [
                <a href="https://www.kittyads.com" className="underline text-guardian-blue">
                  https://www.kittyads.com
                </a>
                ]
              </li>

              <li className="mb-2">
                CallEscort: [
                <a href="https://callescort.org" className="underline text-guardian-blue">
                  https://callescort.org
                </a>
                ]
              </li>

              <li className="mb-2">
                Adult Search: [
                <a href="https://adultsearch.com" className="underline text-guardian-blue">
                  https://adultsearch.com
                </a>
                ]
              </li>

              <li className="mb-2">
                Adult Look: [
                <a href="https://www.adultlook.com" className="underline text-guardian-blue">
                  https://www.adultlook.com
                </a>
                ]
              </li>

              <li className="mb-2">
                Tryst: [
                <a href="https://tryst.link" className="underline text-guardian-blue">
                  https://tryst.link
                </a>
                ]
              </li>

              {/* <li className="mb-2">
                Truth iN Advertising (TNA): [
                <a href="https://www.tnaboard.com" className="underline text-guardian-blue">
                  https://www.tnaboard.com
                </a>
                ]
              </li> */}

              <li className="mb-2">
                Eros: [
                <a href="https://www.eros.com" className="underline text-guardian-blue">
                  https://www.eros.com
                </a>
                ]
              </li>

              <li className="mb-2">
                SumoSearch: [
                <a href="https://sumosear.ch" className="underline text-guardian-blue">
                  https://sumosear.ch
                </a>
                ]
                <ul className="pl-6 list-disc">
                  <li className="mb-2">
                    This is the only aggregator that you can use as a start point, because it's pulling in some of the
                    sites listed above. When sourcing the Step 1 in your report, you must start with the original ad
                    URL, NOT the SumoSearch URL. If the original ad has been removed but it's still in SumoSearch, then
                    you can use the SumoSearch as the start point, but ensure you explain this.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li className="mb-2">
        Lead Submission(s):
        <ol className="pl-6 list-alpha">
          <li className="mb-2">
            You <span className="underline">must</span> identify true name (first & last).
          </li>
          <li className="mb-2">
            You <span className="underline">must</span> identify a date of birth or show convincing evidence that you
            believe the victim is underage, <span className="underline">at the time of the submission.</span>
            <ol className="pl-6 list-roman">
              <li className="mb-2">
                If you go through the process of determining a possible victim's true identity and then realize they are
                between the ages of 18-25, you may still submit through this application. However, it will be reviewed
                after all underage leads are processed. If approved, it will follow the same point awarding process as
                underage leads, but with a significantly less point value.
              </li>
              <li className="mb-2">
                Any lead of an underage victim can be submitted no matter how dated the start point ad is. If the lead
                is of a person 18-25yo, the start point ad must be no older than 24hrs old.
              </li>
            </ol>
          </li>
          <li className="mb-2">
            You must show your steps/work/analysis for each sourced entry, to receive points. This means describing any
            valuable information on the source you've decided to submit.
          </li>
          <li className="mb-2">
            All 'Steps/Sources' must be sourced with a valid link. There are rare occasions that this can't be done. If
            so, explain this in the 'Description' field.
          </li>
          <li className="mb-2">
            Your submission <span className="underline">must</span> provide visual corroboration to other PAI, that
            confirms that the person being advertised in the escort ad matches the true identity you've determined. A
            simple name match won't be sufficient for validation.
          </li>
        </ol>
      </li>
    </ol>
    <p className="font-semibold">Guidance:</p>
    <ol className="pl-6 list-decimal">
      <li className="mb-2">
        There's no requirement or expectation that you submit a single lead. This is completely volunteer and up to you
        as to how you proceed further.
      </li>
      <li className="mb-2">
        You have the freedom to look in any city in the United States, to identify a lead. It's completely your choice.
        Trafficking happens in almost every city, but the volume greatly increases with the size of a city's population.
        If you'd like us to recommend an area for you to look, based on our law enforcement contacts, just ask by
        contacting us through the application feedback.
      </li>
      <li className="mb-2">
        At least 90% of the time, your true identification will come from clues in the ad, whether that is a phone
        number, email, username etc. where you further pivot out to identify and corroborate a true identity and age.
      </li>
    </ol>
  </div>
);

export default RulesAndGuidelines;
