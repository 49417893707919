import { AwardTypes, ESCORT_AD } from '../../constants';
import { personalInfoValidator, escortAdValidator } from './fieldValidation';

export function validateOnSubmit(setAlert, caseState) {
  const escortAdValid = escortAdValidator(setAlert, caseState.escortAd);
  const personalInfoValid = personalInfoValidator(setAlert, caseState);

  return personalInfoValid && escortAdValid;
}

export function validateOnApprove(setAlert, caseState) {
  if (!caseState.awardClassificationOption) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'You must select an Award Classification Option before approval',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  const { awardedPoints } = caseState;

  if (!Array.isArray(awardedPoints) || awardedPoints.length < caseState.webSources.length + 1) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'You must select an award option for each step before approval.',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  const escortAdAward = awardedPoints.find(x => x.stepId === ESCORT_AD);

  if (
    !escortAdAward ||
    (escortAdAward.awardType !== AwardTypes.AccountIdentified &&
      escortAdAward.awardType !== AwardTypes.VerficationSource)
  ) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Escort ad must be awarded points in order to approve a case',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  return true;
}

export function validateOnReturn(setAlert, caseState) {
  if (!Array.isArray(caseState.feedbackComments) || !caseState.feedbackComments.length) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'You must provide at least one feedback comment before returning the case',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  if (!caseState.feedbackComments.some(x => x.feedback)) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'You must provide at least one feedback comment before returning the case!!',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  return true;
}
