import { CaseStatus } from '../../constants';
import { webSourceFields, websiteList } from './webSourceFields';

export const initialPersonalInfoState = {
  firstName: '',
  lastName: '',
  middleName: '',
  age: '',
  city: '',
  state: '',
  details: '',
};

export const initialEscortAdState = {
  url: '',
  phones: [
    {
      type: 'Cellular',
      number: '',
      id: 'initial',
    },
  ],
};

export const initialWebSourceState = {
  website: websiteList[0],
  uniqueFields: { ...webSourceFields[websiteList[0].toLowerCase()] },
  url: '',
  description: '',
};

export const initialCaseState = {
  caseNumber: '',
  firstName: '',
  lastName: '',
  middleName: '',
  age: '',
  city: '',
  state: '',
  details: '',
  escortAd: initialEscortAdState,
  webSources: [],
  status: CaseStatus.Active,
};
