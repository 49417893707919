import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import ShortUniqueId from 'short-unique-id';
import * as Icons from 'react-icons/fa';
import debounce from 'lodash.debounce';
import Switch from 'react-switch';
import { v4 as uuidv4 } from 'uuid';
import { createCase, updateCase, submitCase } from '../../../generated/graphql/mutations';
import { getCase } from '../../../generated/graphql/queries';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';
import { initialCaseState, initialWebSourceState } from '../../../helpers/caseForms/initialFormStates';
import { validateOnSubmit } from '../../../helpers/caseForms/caseValidation';
import { AwardTypes, CaseStatus } from '../../../constants';

import {
  Container,
  MobileDropdown,
  Button,
  PopUpConfirmation,
  Spinner,
  ConfirmationForm,
  OrderStepsForm,
  PersonalInfoForm,
  EscortAdForm,
  WebSourceForm,
} from '../../../components';

const DEBOUNCE_SAVE_DELAY_MS = 3000;

const Investigation = () => {
  const history = useHistory();

  const setAlert = useContext(NotificationContext)[1];

  const { idParam } = useParams();

  const id = idParam === 'new' ? '' : idParam;

  const [caseID, setCaseID] = useState(id);
  const [formNumber, setFormNumber] = useState(0);
  const [caseState, setCaseState] = useState(JSON.parse(JSON.stringify(initialCaseState)));
  const [sourceIndex, setSourceIndex] = useState(0);
  const [numSources, setNumSources] = useState();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [autoSaveReady, setAutoSaveReady] = useState(false);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableOnApprove, setDisableOnApprove] = useState(false);
  const [disableOnApproveEdit, setDisableOnApproveEdit] = useState(false);
  const [rightScroll, setRightScroll] = useState(true);

  useEffect(() => {
    const enabled = localStorage.getItem('autoSaveEnabled');

    if (enabled && enabled.toLocaleLowerCase() === 'false') {
      setAutoSaveEnabled(false);
    }
  }, []);

  const autoSave = useCallback(
    debounce(async data => {
      if (autoSaveReady && autoSaveEnabled) {
        save({ data, noAlert: true });
      }
    }, DEBOUNCE_SAVE_DELAY_MS),
    [autoSaveReady, autoSaveEnabled]
  );

  useEffect(() => {
    if (!id) {
      const initCaseState = JSON.parse(JSON.stringify(initialCaseState));

      initCaseState.caseNumber = generateCaseNumber();

      setCaseState(initCaseState);

      setFormNumber(0);
      setLoading(false);
    } else {
      getCaseRecord().then(record => {
        setAutoSaveReady(true);

        storeState(record);
        if (
          record.status === CaseStatus.Approved ||
          record.status === CaseStatus.Submitted ||
          record.status === CaseStatus.Resubmitted
        ) {
          setFormNumber(2);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    setNumSources(1 + caseState.webSources.length);

    if (caseState.status === CaseStatus.Submitted || caseState.status === CaseStatus.Resubmitted) {
      setDisableEdit(true);
    }

    // checkif any any points to edit
    if (caseState.status === CaseStatus.Approved) {
      setDisableOnApproveEdit(disableApprovedEdit());
    }

    if (caseState.status === CaseStatus.Approved) {
      setDisableOnApprove(true);
    }
  }, [caseState]);

  useEffect(() => {
    if (caseState) {
      autoSave(caseState, false);
    }
  }, [caseState, autoSave]);

  useEffect(() => {
    if (formNumber === 1 && sourceIndex > 0) {
      skipSteps();
    }
  }, [sourceIndex, formNumber]);

  const tabNames = ['Personal Information', 'Case Sources', 'Review Steps', 'Confirm Details'];

  const disableApprovedEdit = () => {
    let noEdit = true;

    caseState.webSources.map(source => {
      const currStepAward = caseState?.awardedPoints?.find(item => item.stepId === source.id) || null;

      if (currStepAward?.awardType === AwardTypes.NoPoints || !currStepAward?.awardType) {
        noEdit = false;
      }
    });

    return noEdit;
  };

  const skipSteps = () => {
    // get awarded points where step id is the same as websource

    let tmpIndex = sourceIndex - 1;
    let currStepAward =
      caseState?.awardedPoints?.find(source => source.stepId === caseState.webSources[tmpIndex].id) || null;

    // skip step if already approved. (so user can't edit)
    if (
      caseState.status === CaseStatus.Approved &&
      (currStepAward?.awardType === AwardTypes.AccountIdentified ||
        currStepAward?.awardType === AwardTypes.VerficationSource)
    ) {
      while (
        currStepAward?.awardType === AwardTypes.AccountIdentified ||
        currStepAward?.awardType === AwardTypes.VerficationSource
      ) {
        if (rightScroll) {
          tmpIndex += 1;
        } else {
          tmpIndex -= 1;
        }

        if (tmpIndex >= caseState.webSources.length || tmpIndex < 0) {
          setFormNumber(formNumber + 1);

          return;
        }

        currStepAward =
          caseState?.awardedPoints?.find(source => source.stepId === caseState.webSources[tmpIndex].id) || null;
      }

      setSourceIndex(tmpIndex + 1);

      if (rightScroll) {
        nextForm(tmpIndex);
      } else {
        prevForm(tmpIndex + 2);
      }
    }
  };
  const generateCaseNumber = () => {
    let number = new ShortUniqueId({
      dictionary: 'number',
    })();

    const timestamp = new Date();
    const year = timestamp.getFullYear();

    number = `t${number}-${year}`;

    return number;
  };

  const storeState = record => {
    const loadedCaseState = JSON.parse(JSON.stringify(record));

    if (loadedCaseState && loadedCaseState.webSources && loadedCaseState.webSources.length) {
      const updatedWebSources = [];

      for (const webSource of loadedCaseState.webSources) {
        if (!webSource.id) {
          updatedWebSources.push({ ...webSource, id: uuidv4() });
        } else {
          updatedWebSources.push(webSource);
        }
      }

      loadedCaseState.webSources = updatedWebSources;
    }

    if (loadedCaseState && loadedCaseState.escortAd && loadedCaseState.escortAd.phones) {
      const updatedPhones = [];

      for (const phone of loadedCaseState.escortAd.phones) {
        if (!phone.id) {
          updatedPhones.push({ ...phone, id: uuidv4() });
        } else {
          updatedPhones.push(phone);
        }
      }

      loadedCaseState.escortAd.phones = updatedPhones;
    }

    setCaseState(loadedCaseState);
    setLoading(false);
  };

  const getCaseRecord = async () => {
    try {
      const res = await API.graphql(
        graphqlOperation(getCase.replace('assignedToName\n', ''), {
          id: caseID,
        })
      );
      return res.data.getCase;
    } catch (error) {
      console.error('Error on getting case', error);
      const { data } = error;
      if (data && data.getCase !== null) {
        return data.getCase;
      }
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error Getting Case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
  };

  const addCaseRecord = async record => {
    try {
      const res = await API.graphql(
        graphqlOperation(createCase.replace('assignedToName\n', ''), {
          input: record,
        })
      );

      const caseId = res.data.createCase.id;

      setCaseID(caseId);

      window.history.replaceState(null, 'Pursuit | Guardian Group', `/investigation/${caseId}`);

      setAutoSaveReady(true);

      return true;
    } catch (error) {
      console.error('Error on creating case', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error Creating Case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });

      return false;
    }
  };

  const updateCaseRecord = async record => {
    record.id = caseID;

    const { assignedToName, ...rest } = record;

    // Remove assignedToName from query, total hack
    const updatedQuery = updateCase.replace('assignedToName\n', '');
    try {
      await API.graphql(
        graphqlOperation(updatedQuery, {
          // input: record,
          input: rest,
        })
      );

      return true;
    } catch (error) {
      console.error('Error on updating case', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error Saving Case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });

      return false;
    }
  };

  const save = async params => {
    let data = null;
    let noAlert = false;

    if (params) {
      ({ data, noAlert } = params);
    }

    setSaving(true);

    removeAlert();

    let saved = true;

    const record = !data ? { ...caseState } : { ...data };

    if (caseID === '') {
      saved = await addCaseRecord({
        caseNumber: record.caseNumber,
        firstName: record.firstName,
        middleName: record.middleName,
        lastName: record.lastName,
        age: record.age,
        city: record.city,
        state: record.state,
        details: record.details,
        escortAd: record.escortAd,
        webSources: record.webSources,
        investigatorNotes: record.investigatorNotes,
      });
    } else {
      saved = await updateCaseRecord({
        id: caseID,
        firstName: record.firstName,
        middleName: record.middleName,
        lastName: record.lastName,
        age: record.age,
        city: record.city,
        state: record.state,
        details: record.details,
        escortAd: record.escortAd,
        webSources: record.webSources,
        // Did notes ever save?
        investigatorNotes: record.investigatorNotes,
      });
    }

    if (!noAlert && saved) {
      toast.success('Successfully Saved', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });
    }

    setSaving(false);

    return saved;
  };

  const autoSaveToggle = enabled => {
    setAutoSaveEnabled(enabled);

    localStorage.setItem('autoSaveEnabled', enabled.toString());
  };

  const submit = () => {
    if (save() === false) return;

    if (validateOnSubmit(setAlert, caseState)) {
      removeAlert();

      setShowConfirmPopup(true);
    }
  };

  const confirmedSubmit = async () => {
    try {
      setSubmitting(true);

      const input = {
        id: caseID,
        firstName: caseState.firstName,
        middleName: caseState.middleName,
        lastName: caseState.lastName,
        age: caseState.age,
        city: caseState.city,
        state: caseState.state,
        details: caseState.details,
        escortAd: caseState.escortAd,
        webSources: caseState.webSources,
        investigatorNotes: caseState.investigatorNotes,
      };

      if ('status' in caseState) {
        input.status = caseState.status;
      }

      await API.graphql(graphqlOperation(submitCase.replace('assignedToName\n', ''), { input }));

      setSubmitting(false);

      history.replace('/success');
    } catch (error) {
      console.error('Error submitting case: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error Submitting Case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });

      setSubmitting(false);
    }
  };

  const removeAlert = () => {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: false,
      },
    });
  };

  const saveAndAdd = async () => {
    const saved = await save();

    if (!saved) {
      return;
    }

    const newWebSource = JSON.parse(JSON.stringify(initialWebSourceState));

    newWebSource.id = uuidv4();

    const updatedWebSources = JSON.parse(JSON.stringify(caseState.webSources));

    updatedWebSources.push(newWebSource);

    const newCaseState = {
      ...caseState,
      webSources: [...updatedWebSources],
    };

    setCaseState(newCaseState);

    setSourceIndex(numSources);
    setNumSources(numSources + 1);

    setFormNumber(1);
  };

  const prevForm = (srcIndex = sourceIndex) => {
    if (autoSaveEnabled) {
      if (!save({ noAlert: true })) {
        return;
      }
    }
    setRightScroll(false);

    if (formNumber === 1) {
      if (srcIndex <= 1) {
        // if approved don't let user update personal info
        if (caseState.status === CaseStatus.Approved) {
          setFormNumber(2);
        } else {
          setFormNumber(formNumber - 1);
        }
      } else {
        setSourceIndex(srcIndex - 1);
      }

      return;
    }

    if (formNumber === 2) {
      setFormNumber(formNumber - 1);
      setSourceIndex(numSources - 1);

      return;
    }

    setFormNumber(formNumber - 1);
  };

  const nextForm = async (srcIndex = sourceIndex) => {
    const saved = await save();

    if (!saved) {
      return;
    }
    setRightScroll(true);

    if (formNumber === 1 && srcIndex < numSources - 1) {
      setSourceIndex(srcIndex + 1);

      return;
    }

    if (formNumber === 0) {
      setSourceIndex(0);
    }

    setFormNumber(formNumber + 1);
  };

  const saveAndTo = async tabNum => {
    if (autoSaveEnabled) {
      const saved = await save({ noAlert: true });

      if (!saved) {
        return;
      }
    }
    if (caseState.status === CaseStatus.Approved) {
      setSourceIndex(1);
    } else {
      setSourceIndex(0);
    }

    setFormNumber(tabNum);
  };

  const editSource = index => {
    if (!save()) {
      return;
    }

    setSourceIndex(index);
    setFormNumber(1);
  };

  // Component Builders
  const defaultTab = (formNum, text, disable = false) => (
    <Button
      tab
      onClick={() => saveAndTo(formNum, true)}
      className="px-0 text-lg border-b-4 border-transparent rounded-none text-pursuit-gray focus:outline-none hover:border-pursuit-red"
      disabled={disable}>
      {text}
    </Button>
  );

  const activateTab = tabButton => (
    <div className="relative flex flex-col">
      {tabButton}
      <div className="absolute inset-y-0 w-full border-b-4 border-pursuit-red" />
    </div>
  );

  let personalInfoTab = defaultTab(0, 'Personal Information', disableEdit || disableOnApprove);
  let sourcesTab = defaultTab(1, 'Case Sources', disableEdit || disableOnApproveEdit);
  let reviewTab = defaultTab(2, 'Review Steps');
  let confirmTab = defaultTab(3, 'Confirm Details');

  let prevButton = (
    <Button
      className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue"
      onClick={prevForm}
      disabled={disableEdit && formNumber === 2}>
      <Icons.FaChevronLeft size={18} className="mr-4" />
      BACK
    </Button>
  );

  let nextButton = (
    <Button
      className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue"
      onClick={nextForm}>
      SAVE AND NEXT
      <Icons.FaChevronRight size={18} className="ml-4" />
    </Button>
  );

  const saveAndAddButton = (
    <Button solidBlue padding="px-4 py-1" className="lg:w-88" onClick={saveAndAdd}>
      <span className="p-0 m-0 nowrap" style={{ whiteSpace: 'nowrap' }}>
        SAVE AND ADD NEW SOURCE
      </span>
    </Button>
  );

  const addButton = (
    <Button solidBlue padding="px-4 py-1" className="my-2 mr-8" onClick={saveAndAdd} disabled={disableEdit}>
      <span className="p-0 m-0 nowrap" style={{ whiteSpace: 'nowrap' }}>
        Add New Source
      </span>
    </Button>
  );

  const submitButton = (submitText = 'SAVE & SUBMIT TO GUARDIAN GROUP') => (
    <Button solidBlue onClick={submit} className="text-base w-72">
      {submitText}
    </Button>
  );

  let form = null;

  switch (formNumber) {
    case 0:
      personalInfoTab = activateTab(personalInfoTab);
      form = (
        <PersonalInfoForm
          caseState={caseState}
          setCaseState={setCaseState}
          key={`personalinfo-${caseState.caseNumber}`}
        />
      );
      prevButton = <div />;
      break;
    case 1:
      sourcesTab = activateTab(sourcesTab);
      form =
        sourceIndex === 0 ? (
          <EscortAdForm
            caseState={caseState}
            setCaseState={setCaseState}
            saveAndAddButton={saveAndAddButton}
            key={`escort-${caseState.caseNumber}`}
          />
        ) : (
          <WebSourceForm
            caseState={caseState}
            setCaseState={setCaseState}
            stepNumber={sourceIndex + 1}
            webSourceIndex={sourceIndex - 1}
            setGlobalIndex={setSourceIndex}
            setFormNumber={setFormNumber}
            saveAndAddButton={saveAndAddButton}
            // key={`websource-${caseState.webSources[sourceIndex - 1].id}`}
          />
        );
      break;
    case 2:
      reviewTab = activateTab(reviewTab);
      form = (
        <OrderStepsForm
          caseState={caseState}
          setCaseState={setCaseState}
          editSource={editSource}
          save={save}
          addButton={addButton}
          disableEdit={disableEdit}
          sourceIndex={sourceIndex}
        />
      );
      break;
    case 3:
      confirmTab = activateTab(confirmTab);
      form = <ConfirmationForm caseState={caseState} />;
      const stepsAdded = caseState.awardedPoints?.length - 1 !== caseState?.webSources?.length;

      if (caseState.status === CaseStatus.Active || caseState.status === CaseStatus.Returned) {
        nextButton = submitButton();
      } else if (
        (caseState.status === CaseStatus.Approved && caseState.totalPoints.isFullPoints === false) ||
        (caseState.status === CaseStatus.Approved && stepsAdded)
      ) {
        nextButton = submitButton('RESUBMIT CASE');
      } else if (caseState.status === CaseStatus.Approved) {
        nextButton = <span className="text-lg font-bold text-pursuit-gray"> Approved </span>;
      } else if (caseState.status === CaseStatus.Resubmitted) {
        nextButton = <span className="text-lg font-bold text-pursuit-gray"> Resubmitted </span>;
      } else {
        nextButton = <span className="text-lg font-bold text-pursuit-gray">Pending Approval</span>;
      }
      break;
    default:
      break;
  }

  const getCaseState = () => {
    const { status } = caseState;

    switch (status) {
      case CaseStatus.Active || null || '':
        return 'In Progress';
      case CaseStatus.Approved:
        return 'Approved';
      case CaseStatus.Resubmitted:
        return 'Resubmitted';
      case CaseStatus.Returned:
        return 'Returned';
      case CaseStatus.Submitted:
        return 'Submitted for Review';
      default:
        return '-';
    }
  };

  return loading ? (
    <Container
      width="flex flex-col w-96pr h-88 lg:w-3/4 justify-center items-center"
      margin="m-3 lg:m-auto"
      className="relative px-6 py-8">
      <div className="flex flex-col items-center justify-center w-120">
        <div className="mb-6 text-xl font-bold text-pursuit-gray">Loading Case Details</div>
        <Spinner />
      </div>
    </Container>
  ) : (
    <>
      {showConfirmPopup ? (
        <PopUpConfirmation
          title="Confirm Case Submission"
          content="Are you sure you are ready to submit this case to Guardian Group?"
          onCancel={() => setShowConfirmPopup(false)}
          onConfirm={() => confirmedSubmit()}
          confirmText="SUBMIT"
          confirmLoading={submitting}
          confirmLoadingText="SUBMITTING"
          className="w-11/12 lg:w-auto"
        />
      ) : null}
      <div className="relative flex flex-col flex-1 w-full lg:my-8">
        {saving && (
          <div className="fixed top-0 right-0 hidden p-2 mt-24 lg:block">
            <Spinner />
          </div>
        )}
        <Container width="lg:w-3/4" margin="m-3 lg:m-auto" className="relative px-3 py-4 lg:px-6 lg:py-8">
          <div className="p-2 font-bold">
            <h1 className="text-xl lg:text-4xl text-pursuit-gray">Case #{caseState.caseNumber}</h1>
            <h2 className="text-sm text-pursuit-red lg:text-xl">{getCaseState()}</h2>
          </div>
          <div className="lg:relative">
            <div className="flex items-center mt-4">
              <span className="text-pursuit-gray">Auto Save</span>
              <Switch
                onChange={autoSaveToggle}
                checked={autoSaveEnabled}
                className="ml-2 mr-4"
                height={20}
                width={40}
              />
            </div>

            <div className="flex m-auto mt-2 mb-4 lg:hidden">
              <MobileDropdown
                className="w-full h-10 px-2 bg-white shadow"
                optionsArray={tabNames}
                selected={tabNames[formNumber]}
                selectionCallback={formName => saveAndTo(tabNames.indexOf(formName))}
              />
            </div>
            <div className="absolute bottom-0 right-0 flex-row hidden pr-4 mt-4 mb-2 lg: lg:flex">
              {personalInfoTab}
              <span className="ml-6">{sourcesTab}</span>
              <span className="ml-6">{reviewTab}</span>
              <span className="ml-6">{confirmTab}</span>
            </div>
          </div>
          <div className="w-full px-2 py-4 my-2 bg-gray-100 lg:px-8 lg:py-8">
            {form}
            <div className="flex flex-row justify-between h-10 mt-8 text-xs">
              {prevButton}
              {nextButton}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Investigation;
