import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import debounce from 'lodash.debounce';
import Switch from 'react-switch';
import { employeeUpdateCase, approveCase, returnCase, unapproveCase } from '../../../generated/graphql/mutations';
import { getCase } from '../../../generated/graphql/queries';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';
import { CaseStatus, AwardTypes, ESCORT_AD } from '../../../constants';
import { validateOnApprove, validateOnReturn } from '../../../helpers/caseForms/caseValidation';
import { initialWebSourceState } from '../../../helpers/caseForms/initialFormStates';
import { useQueryState } from '../../../helpers/useQueryState';

import {
  Container,
  MobileDropdown,
  Button,
  PopUpConfirmation,
  Spinner,
  ReviewForm,
  ReportBuilder,
  PersonalInfoForm,
  EscortAdForm,
  WebSourceForm,
  MessageForm,
} from '../../../components';
import { useUser } from '../../../contexts/userContext';
import { getInitialCropFromCroppedAreaPercentages } from 'react-easy-crop';

import { compileSteps } from '../../../components/ReviewForm/compileSteps';

const DEBOUNCE_SAVE_DELAY_MS = 3000;

const CaseReview = () => {
  const history = useHistory();

  const [, setAlert] = useContext(NotificationContext);

  const { idParam: id } = useParams();
  const { user } = useUser();

  const [caseState, setCaseState] = useState();
  const [compiledSteps, setCompiledSteps] = useState([]);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formNumber, setFormNumber] = useState(2);
  const [sourceIndex, setSourceIndex] = useState(0);
  const [numSources, setNumSources] = useState(0);

  const [autoSaveReady, setAutoSaveReady] = useState(false);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);

  const [approveReady, setApproveReady] = useState(false);
  const [approving, setApproving] = useState(false);

  const [returnReady, setReturnReady] = useState(false);
  // Checked when state changes to determine if the form is valid
  const [returnValid, setReturnValid] = useState(false);
  const [returning, setReturning] = useState(false);

  const [unapproveReady, setUnapproveReady] = useState(false);
  const [unapproving, setUnapproving] = useState(false);

  const [title, setTitle] = useState('');

  const [showAddStepForm, setShowAddStepForm] = useState(false);
  const [sendMessageOnAction, setSendMessageOnAction] = useState(true);
  const [sendMessageOnApproval, setSendMessageOnApproval] = useState(true);
  // Toggle sending message on approve/return
  const toggleSendMessageOnAction = () => {
    setSendMessageOnAction(current => !current);
  };

  // Form number to return to after saving a case
  const [returnFormNumber, setReturnFormNumber] = useQueryState('returnForm');

  useEffect(() => {
    const enabled = localStorage.getItem('autoSaveEnabled');

    if (enabled && enabled.toLocaleLowerCase() === 'false') {
      setAutoSaveEnabled(false);
    }
  }, []);

  const autoSave = useCallback(
    debounce(async data => {
      if (autoSaveReady && autoSaveEnabled) {
        save({ data, noAlert: true });
      }
    }, DEBOUNCE_SAVE_DELAY_MS),
    [autoSaveReady, autoSaveEnabled]
  );

  // When return ready changes, check if the form is valid
  // useEffect(() => {
  //   if (returnReady) {
  //     const valid = validateOnReturn(setAlert, caseState);
  //     setReturnValid(valid);
  //   }
  // }, [returnReady, caseState]);

  useEffect(() => {
    const getCaseRecord = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getCase, {
            id,
          })
        );

        setAutoSaveReady(true);

        const caseData = result.data.getCase;

        storeState(caseData);
        setTitle(`Case #${result.data.getCase.caseNumber}`);

        const { status } = caseData;

        if (status === CaseStatus.Approved) {
          setFormNumber(3);
        } else {
          setFormNumber(2);
        }
      } catch (error) {
        console.error('ERROR: ', error);
      }
    };

    getCaseRecord();
  }, [id]);

  useEffect(() => {
    if (caseState && caseState.webSources) {
      setNumSources(1 + caseState.webSources.length);
    } else {
      setNumSources(0);
    }
  }, [caseState]);

  useEffect(() => {
    if (!caseState || !caseState.webSources) {
      return;
    }
    const awards = caseState.awardedPoints ? [...caseState.awardedPoints] : [];
    const feedback = caseState.feedbackComments ? [...caseState.feedbackComments] : [];
    const steps = [
      {
        title: 'Escort Ad',
        body: caseState.escortAd,
        id: ESCORT_AD,
        feedback: feedback.find(x => x.stepId === ESCORT_AD)?.feedback || '',
        award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      },
    ];
    const webSources = caseState.webSources.map(source => ({
      title: source.website ? source.website : '[no source type selected]',
      body: source,
      id: source.id,
      feedback: feedback.find(x => x.stepId === source.id)?.feedback || '',
      award: awards.find(x => x.stepId === source.id)?.awardType || null,
    }));
    const staffSources = caseState.staffSources
      ? caseState.staffSources.map(ss => {
          const { author, previousSourceId, ...commonFields } = ss;

          return {
            title: ss.website ? ss.website : '[no source type selected]',
            body: commonFields,
            previousSourceId,
            author,
            id: ss.id,
            feedback: feedback.find(x => x.stepId === ss.id)?.feedback || '',
            award: awards.find(x => x.stepId === ss.id)?.awardType || null,
          };
        })
      : [];
    const cs = compileSteps(steps, webSources, staffSources);

    // console.log({ cs });
    setCompiledSteps(cs);
  }, [caseState]);

  useEffect(() => {
    if (
      caseState &&
      caseState.webSources &&
      sourceIndex > caseState.webSources.length + (caseState.staffSources ? caseState.staffSources.length : 0)
    ) {
      if (formNumber < 2) {
        setFormNumber(formNumber + 1);
      }
    }
  }, [caseState, formNumber, sourceIndex]);

  useEffect(() => {
    if (caseState) {
      autoSave(caseState, false);
    }
  }, [caseState, autoSave]);

  const tabNames = ['Personal Information', 'Case Sources', 'Review Details', 'Case Report'];

  const storeState = record => {
    const loadedCaseState = JSON.parse(JSON.stringify(record));

    setCaseState(loadedCaseState);
    setLoading(false);
  };

  const updateCaseRecord = async record => {
    try {
      await API.graphql(
        graphqlOperation(employeeUpdateCase, {
          input: record,
        })
      );

      return true;
    } catch (error) {
      console.error('Error on updating case', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error Saving Case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });

      return false;
    }
  };

  const save = async params => {
    // console.log('Saving case', { params });
    let data = null;
    let noAlert = false;

    if (params) {
      ({ data, noAlert } = params);
    }

    setSaving(true);

    removeAlert();

    const record = !data ? { ...caseState } : { ...data };

    const saved = await updateCaseRecord({
      id,
      firstName: record.firstName,
      middleName: record.middleName,
      lastName: record.lastName,
      age: record.age,
      city: record.city,
      state: record.state,
      details: record.details,
      escortAd: record.escortAd,
      webSources: record.webSources,
      staffSources: record.staffSources || null,
      investigatorNotes: record.investigatorNotes || null,
      awardClassificationOption: record.awardClassificationOption || null,
      feedbackComments: record.feedbackComments || [],
      awardedPoints: record.awardedPoints || [],
    });

    if (!noAlert && saved) {
      toast.success('Successfully Saved', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });
    }

    setSaving(false);

    return saved;
  };

  const approveCaseAndAwardPoints = async () => {
    try {
      setApproving(true);

      const caseStateIsValid = validateOnApprove(setAlert, caseState);

      if (caseStateIsValid) {
        const saved = await save({ noAlert: true });

        if (saved) {
          const result = await API.graphql(
            graphqlOperation(approveCase, {
              id,
            })
          );

          setCaseState({ ...caseState, ...result.data.approveCase });

          toast.success('Case Approved', {
            progress: false,
            className: 'bg-green-500 text-white',
            autoClose: 1500,
            closeButton: false,
            icon: () => <Icons.FaCheck size={18} className="text-white" />,
          });
        }
      }
    } catch (error) {
      console.error('Error approving case: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An Error occurred approving the case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setApproving(false);
    setApproveReady(false);
  };

  const returnToVolunteer = async () => {
    try {
      setReturning(true);

      // const caseStateIsValid = validateOnReturn(setAlert, caseState);
      // Returning is only done with message form which provides feedback
      const caseStateIsValid = true;

      if (caseStateIsValid) {
        const saved = await save({ noAlert: true });

        if (saved) {
          await API.graphql(
            graphqlOperation(returnCase, {
              id,
            })
          );

          toast.success('Case Returned', {
            progress: false,
            className: 'bg-green-500 text-white',
            autoClose: 1500,
            closeButton: false,
            icon: () => <Icons.FaCheck size={18} className="text-white" />,
          });

          history.replace('/');
        }
      }
    } catch (error) {
      console.error('Error returning case: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An Error occurred returning the case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setReturning(false);
    setReturnReady(false);
  };

  const unapproveAndReturnToVolunteer = async () => {
    try {
      // setReturning(true);
      setUnapproving(true);

      // const caseStateIsValid = validateOnReturn(setAlert, caseState);
      // Returning is only done with message form which provides feedback
      const caseStateIsValid = true;

      if (caseStateIsValid) {
        const saved = await save({ noAlert: true });

        if (saved) {
          await API.graphql(
            graphqlOperation(unapproveCase, {
              id,
            })
          );

          toast.success('Case Unapproved', {
            progress: false,
            className: 'bg-green-500 text-white',
            autoClose: 1500,
            closeButton: false,
            icon: () => <Icons.FaCheck size={18} className="text-white" />,
          });

          history.replace('/');
        }
      }
    } catch (error) {
      console.error('Error unapproving case: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An Error occurred unapproving the case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setUnapproving(false);
    setUnapproveReady(false);
  };

  const autoSaveToggle = enabled => {
    setAutoSaveEnabled(enabled);

    localStorage.setItem('autoSaveEnabled', enabled.toString());
  };

  const removeAlert = () => {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: false,
      },
    });
  };

  const prevForm = () => {
    if (autoSaveEnabled) {
      if (!save({ noAlert: true })) {
        return;
      }
    }

    if (formNumber === 1 && sourceIndex > 0) {
      setSourceIndex(sourceIndex - 1);

      return;
    }

    if (formNumber === 2) {
      setSourceIndex(0);
    }

    setFormNumber(formNumber - 1);
  };

  const nextForm = async () => {
    const saved = await save();

    if (!saved) {
      return;
    }

    if (formNumber === 1 && sourceIndex < numSources - 1) {
      setSourceIndex(sourceIndex + 1);

      return;
    }

    if (formNumber === 0) {
      setSourceIndex(0);
    }

    setFormNumber(formNumber + 1);
  };

  const saveAndTo = async tabNum => {
    if (autoSaveEnabled) {
      const saved = await save({ noAlert: true });

      if (!saved) {
        return;
      }
    }

    setSourceIndex(0);
    setFormNumber(tabNum);
  };

  const addStaffSource = async () => {
    const newWebSource = JSON.parse(JSON.stringify(initialWebSourceState));

    newWebSource.id = uuidv4();

    const updatedWebSources = caseState.staffSources ? JSON.parse(JSON.stringify(caseState.staffSources)) : [];
    const lastWebSourceId =
      Array.isArray(caseState.webSources) && caseState.webSources.length > 0
        ? caseState.webSources[caseState.webSources.length - 1].id
        : null;

    const lastStaffSourceId = updatedWebSources.length > 0 ? updatedWebSources[updatedWebSources.length - 1].id : null;

    newWebSource.previousSourceId = lastStaffSourceId || lastWebSourceId;

    // This put things in the wrong spot
    // const potentialLastStaffSource = updatedWebSources.find(
    //   ({ previousSourceId }) => previousSourceId === lastWebSourceId
    // );

    // if (potentialLastStaffSource) {
    //   newWebSource.previousSourceId = potentialLastStaffSource.id;
    // }

    if (user && user.id) {
      newWebSource.author = user.id;

      // if (updatedWebSources.length > 0) {
      //   previousSourceId = updatedWebSources[updatedWebSources.length - 1].id;
      // }
      // newWebSource.previousSourceId = previousSourceId;
      // newWebSource.staffName = [user.firstName, user.lastName].filter(Boolean).join(' ');
    }
    const updatedAwardedPoints = caseState.awardedPoints ? JSON.parse(JSON.stringify(caseState.awardedPoints)) : [];

    updatedWebSources.push(newWebSource);
    // console.log('Updated web sources', { updatedWebSources });

    updatedAwardedPoints.push({
      stepId: newWebSource.id,
      awardType: AwardTypes.NoPoints,
    });
    const newCaseState = {
      ...caseState,
      // awardClassificationOption: caseState.awardClassificationOption || null,
      staffSources: [...updatedWebSources],
      awardedPoints: [...updatedAwardedPoints],
    };

    setCaseState(newCaseState);
    setShowAddStepForm(true);

    // setSourceIndex(numSources);
    // setNumSources(numSources + 1);

    // setFormNumber(1);
  };
  const addSourceButton = (
    <Button solidBlue padding="px-4 py-1" className="my-2 mr-8" onClick={addStaffSource} /* disabled={disableEdit} */>
      <span className="p-0 m-0 nowrap" style={{ whiteSpace: 'nowrap' }}>
        Add New Source
      </span>
    </Button>
  );

  const saveStaffSourceButton = (
    <Button
      solidBlue
      padding="lg:ml-8"
      className="mt-4 lg:w-88 lg:mt-0"
      onClick={() => {
        setShowAddStepForm(false);
        saveAndTo(2);
      }} /* disabled={disableEdit} */
    >
      <span className="p-0 m-0 nowrap" style={{ whiteSpace: 'nowrap' }}>
        SAVE
      </span>
    </Button>
  );

  const addActions = !showAddStepForm && (
    <div className="flex flex-col-reverse lg:flex-row-reverse">
      {addSourceButton}
      {/* {addNoteButton} */}
    </div>
  );

  const defaultTab = (formNum, text) => (
    <Button
      tab
      onClick={() => saveAndTo(formNum, true)}
      className="px-0 text-lg border-b-4 border-transparent rounded-none text-pursuit-gray focus:outline-none hover:border-pursuit-red">
      {text}
    </Button>
  );

  const activateTab = tabButton => (
    <div className="relative flex flex-col">
      {tabButton}
      <div className="absolute inset-y-0 w-full border-b-4 border-pursuit-red" />
    </div>
  );

  let personalInfoTab = defaultTab(0, 'Personal Information');
  let sourcesTab = defaultTab(1, 'Case Sources');
  let reviewTab = defaultTab(2, 'Review Details');
  let reportTab = defaultTab(3, 'Case Report');

  let prevButton = (
    <Button
      className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue"
      onClick={prevForm}>
      <Icons.FaChevronLeft size={18} className="mr-4" />
      BACK
    </Button>
  );

  let nextButton = (
    <Button
      className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue"
      onClick={nextForm}>
      SAVE AND NEXT
      <Icons.FaChevronRight size={18} className="ml-4" />
    </Button>
  );
  const saveEditButton = (
    <Button
      className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue"
      onClick={() => {
        console.log('save edit button clicked', { returnFormNumber });
        setReturnFormNumber(null);

        return saveAndTo(+returnFormNumber);
      }}>
      SAVE
      <Icons.FaChevronRight size={18} className="ml-4" />
    </Button>
  );

  const approveButton = (
    <Button
      solidBlue
      onClick={() => {
        setApproveReady(true);
      }}
      className="w-full mt-4 text-base lg:mt-0 lg:w-64">
      APPROVE CASE
    </Button>
  );

  const reApproveButton = (
    <Button
      solidBlue
      onClick={() => {
        setApproveReady(true);
      }}
      className="w-full mt-4 text-base lg:mt-0 lg:w-64">
      REAPPROVE CASE
    </Button>
  );

  const returnButton = (
    <Button solidRed onClick={() => setReturnReady(true)} className="w-full mt-4 text-base lg:mt-0 lg:w-64 lg:mr-6">
      RETURN TO VOLUNTEER
    </Button>
  );

  const unapproveButton = (
    <Button solidRed onClick={() => setUnapproveReady(true)} className="w-full mt-4 text-base lg:mt-0 lg:w-64 lg:mr-6">
      RETURN TO VOLUNTEER
    </Button>
  );

  const editSource = index => {
    // if (!save()) {
    //   return;
    // }
    console.log('Edit source', { index });
    setReturnFormNumber(2);

    setSourceIndex(index);
    setFormNumber(1);
  };

  // const messageButton = (
  //   <span
  //     // solidBlue
  //     onClick={() => {
  //       const title = `Case Feedback`;
  //       const message = `The case ${caseState.caseNumber}...`;
  //       history.push(`/messaging/create?recipients=${caseState.owner}&message=${message}&title=${title}`);
  //     }}>
  //     Message
  //   </span>
  // );

  const renderReviewButtons = () => {
    if (caseState && caseState.status === CaseStatus.Submitted) {
      return (
        <div className="flex flex-col-reverse lg:flex-row ">
          {/* {messageButton} */}
          {returnButton}
          {approveButton}
        </div>
      );
    }

    if (caseState && caseState.status === CaseStatus.Resubmitted) {
      return (
        <div className="flex flex-col">
          <div className="flex flex-col lg:flex-row ">
            {returnButton}
            {reApproveButton}
          </div>
        </div>
      );
    }

    if (caseState && caseState.status === CaseStatus.Approved) {
      return (
        <div className="flex flex-col">
          <div className="flex flex-col lg:flex-row lg:-mr-6">
            {unapproveButton}
            {/* {reApproveButton} */}
          </div>
        </div>
      );
    }

    return <></>;
  };

  let form = null;

  if (caseState && caseState._deleted === true) {
    return (
      <Container
        width="flex flex-col w-96pr h-88 lg:w-3/4 justify-center items-center"
        margin="m-3 lg:m-auto"
        className="relative px-6 py-8">
        <div className="flex flex-col items-center justify-center w-120">
          <div className="mb-6 text-xl font-bold text-pursuit-gray">This case has been deleted.</div>
        </div>
      </Container>
    );
  }

  switch (formNumber) {
    case 0:
      personalInfoTab = activateTab(personalInfoTab);
      form = (
        <PersonalInfoForm
          caseState={caseState}
          setCaseState={setCaseState}
          key={`personalinfo-${caseState.caseNumber}`}
          reviewing
        />
      );
      prevButton = <div />;
      break;
    case 1:
      sourcesTab = activateTab(sourcesTab);
      // const selectedSource = compiledSteps[webSourceIndex];
      let selectedIndex = (caseState.webSources || []).findIndex(s => s.id === compiledSteps[sourceIndex].id);

      if (selectedIndex === -1) {
        selectedIndex = (caseState.staffSources || []).findIndex(s => s.id === compiledSteps[sourceIndex].id);
      }

      // console.log('test', { selectedIndex, compiledSteps, sourceIndex, caseState });

      form =
        sourceIndex === 0 ? (
          <EscortAdForm
            caseState={caseState}
            setCaseState={setCaseState}
            key={`escort-${caseState.caseNumber}`}
            reviewing
          />
        ) : (
          <WebSourceForm
            caseState={caseState}
            setCaseState={setCaseState}
            stepNumber={sourceIndex + 1}
            // webSourceIndex={sourceIndex - 1}
            // webSourceIndex={caseState.staffSources.length - 1}
            webSourceIndex={
              // sourceIndex <= caseState.webSources.length
              //   ? sourceIndex - 1
              //   : sourceIndex - caseState.webSources.length - 1
              selectedIndex
              // caseState.webSources.findIndex(s => s.id === compiledSteps[sourceIndex]?.id) - 1 ||
              // caseState.staffSources.findIndex(s => s.id === compiledSteps[sourceIndex]?.id) - 1
            }
            // staffSource={sourceIndex > caseState.webSources.length}
            staffSource={(caseState.staffSources || []).findIndex(s => s.id === compiledSteps[sourceIndex].id) !== -1}
            setGlobalIndex={setSourceIndex}
            key={`websource-${caseState.webSources[sourceIndex - 1]?.id}`}
            reviewing
          />
        );
      break;

    case 2:
      reviewTab = activateTab(reviewTab);

      form = showAddStepForm ? (
        <WebSourceForm
          caseState={caseState}
          setCaseState={setCaseState}
          stepNumber={caseState.webSources.length + caseState.staffSources.length + 1}
          webSourceIndex={caseState.staffSources.length - 1}
          saveAndAddButton={saveStaffSourceButton}
          removeLabel="CANCEL"
          // Functions needed to handle delete after its been created
          setFormNumber={_ => {}}
          setGlobalIndex={_ => {
            setShowAddStepForm(false);
          }}
          staffSource
        />
      ) : (
        <>
          <ReviewForm
            caseState={caseState}
            setCaseState={setCaseState}
            stepActions={addActions}
            editAction={editSource}
            // setTitle={setTitle}
            // approveCaseAndAwardPoints={approveCaseAndAwardPoints}
          />
        </>
      );
      nextButton = !showAddStepForm ? renderReviewButtons() : null;
      break;

    case 3:
      reportTab = activateTab(reportTab);
      form = <ReportBuilder id={id} caseState={caseState} setCaseState={setCaseState} setTitle={setTitle} />;
      // nextButton = <></>;
      // An approved case might need to be returned
      nextButton = renderReviewButtons();
      break;
    default:
      break;
  }

  const getCaseState = () => {
    const { status } = caseState;

    switch (status) {
      case CaseStatus.Active:
        return 'In Progress';
      case CaseStatus.Approved:
        return 'Approved';
      case CaseStatus.Returned:
        return 'Returned';
      case CaseStatus.Submitted:
        return 'Submitted for Review';
      case CaseStatus.Resubmitted:
        return 'Resubmitted for Review';
      default:
        return '-';
    }
  };

  const approveFormContent = caseState && (
    <>
      <p>Are you sure you are ready to approve this case and award points to the submitter?</p>
      <div>
        <input
          type="checkbox"
          checked={sendMessageOnApproval}
          onChange={e => setSendMessageOnApproval(e.target.checked)}
        />{' '}
        Send message?
      </div>
      {sendMessageOnApproval && (
        <MessageForm
          notification={{
            recipients: [{ recipient: caseState.owner }],
            title: 'Case Feedback',
            subject: `Case ${caseState.caseNumber}`,
            message: `Your case has been approved. You have been awarded points for your submission.\n
Please review any individual source/step feedback within your case if necessary.\n
Thank you for your contribution!`,
          }}
          // isReply={isReply}
          createLabel="Approve"
          clearLabel="Reset"
          cancelLabel={'Cancel'}
          enableCreate={
            (caseState.awardClassificationOption &&
              Array.isArray(caseState.awardedPoints) &&
              caseState.awardedPoints.length ==
                caseState.webSources.length + (caseState.staffSources ? caseState.staffSources.length : 0) + 1) ||
            caseState.status === CaseStatus.Resubmitted
          }
          // cancelCallback={() => setReturnReady(false)}
          // createCallback={() => alert("Created")}
          // enableCreate={returnValid}
          // createCallback={() => returnToVolunteer()}
          cancelCallback={() => setApproveReady(false)}
          createCallback={() => approveCaseAndAwardPoints()}
          cancelLink={'#'}
          noUserSelector
          noTitle
          subjectDisabled
        />
      )}
      {(!caseState.awardClassificationOption ||
        !Array.isArray(caseState.awardedPoints) ||
        !(caseState.awardedPoints.length == caseState.webSources.length + 1)) &&
        caseState.status !== CaseStatus.Resubmitted && (
          <div style={{ color: '#666', textAlign: 'right' }}>Points must be assigned before approving the case.</div>
        )}
    </>
  );

  const returnFormContent = caseState && (
    // const title = `Case Feedback`;
    //       const message = `The case ${caseState.caseNumber}...`;
    <div style={{ width: '40em' }}>
      <p>Are you sure you want to return this case to the volunteer without awarding points?</p>
      {/* <div>
        Send message?{' '}
        <input type="checkbox" checked={sendMessageOnAction} onChange={e => setSendMessageOnAction(e.target.checked)} />
      </div> */}
      {sendMessageOnAction && (
        <MessageForm
          notification={{
            recipients: [{ recipient: caseState.owner }],
            title: 'Case Feedback',
            subject: `Case ${caseState.caseNumber}`,
            message:
              'Please review the individual source feedback from our staff screening of your case submission. You can access this by opening the returned case and selecting the "Expand All" within the "Review Steps" section. Once you\'ve completed the corrections, you can resubmit the case for another review by us. If you have questions about our feedback, please click Reply to this message and we will answer your questions accordingly. Thanks for your continued efforts.',
          }}
          // isReply={isReply}
          createLabel="Return"
          cancelLabel={'Cancel'}
          clearLabel="Reset"
          cancelCallback={() => setReturnReady(false)}
          // createCallback={() => alert("Created")}
          // enableCreate={returnValid}
          createCallback={() => returnToVolunteer()}
          cancelLink={'#'}
          noUserSelector
          noTitle
          subjectDisabled
        />
      )}
    </div>
  );

  const unapproveFormContent = caseState && (
    // const title = `Case Feedback`;
    //       const message = `The case ${caseState.caseNumber}...`;
    <div style={{ width: '40em' }}>
      <p>Are you sure you want to return this case to the volunteer and remove awarding points?</p>
      {/* <div>
        Send message?{' '}
        <input type="checkbox" checked={sendMessageOnAction} onChange={e => setSendMessageOnAction(e.target.checked)} />
      </div> */}
      {sendMessageOnAction && (
        <MessageForm
          notification={{
            recipients: [{ recipient: caseState.owner }],
            title: 'Case Feedback',
            subject: `Case ${caseState.caseNumber}`,
            message:
              `After further review of the submitted and approved case, it was determined that it was an error on our part to have approved your case. We have changed the status of the case to "Returned". The points that have been previously awarded for this case have been removed. We apologize for the oversight.

Please review any individual source/step feedback within your case if necessary. If you have any questions, please click Reply to this message and we will answer your questions accordingly. Thanks for your continued efforts.`
          }}
          // isReply={isReply}
          createLabel="Return"
          cancelLabel={'Cancel'}
          clearLabel="Reset"
          cancelCallback={() => setUnapproveReady(false)}
          // createCallback={() => alert("Created")}
          enableCreate={!unapproving}
          createCallback={() => unapproveAndReturnToVolunteer()}
          cancelLink={'#'}
          noUserSelector
          noTitle
          subjectDisabled
        />
      )}
    </div>
  );

  return loading ? (
    <Container
      width="flex flex-col w-96pr h-88 lg:w-3/4 justify-center items-center"
      margin="m-3 lg:m-auto"
      className="relative px-6 py-8">
      <div className="flex flex-col items-center justify-center w-120">
        <div className="mb-6 text-xl font-bold text-pursuit-gray">Loading Case Details</div>
        <Spinner />
      </div>
    </Container>
  ) : (
    <>
      {approveReady ? (
        <PopUpConfirmation
          noCancel={sendMessageOnApproval}
          noConfirm={sendMessageOnApproval}
          title="Confirm Case Approval"
          // content="Are you sure you are ready to approve this case and award points to the submitter?"
          content={approveFormContent}
          onCancel={() => setApproveReady(false)}
          onConfirm={() => approveCaseAndAwardPoints()}
          confirmText="APPROVE"
          confirmLoading={approving}
          confirmLoadingText="APPROVING"
          className="w-11/12 lg:w-auto"
        />
      ) : null}

      {returnReady ? (
        <PopUpConfirmation
          noCancel={sendMessageOnAction}
          noConfirm={sendMessageOnAction}
          title="Confirm Case Return"
          // content="Are you sure you want to return this case to the volunteer without awarding points?"
          content={returnFormContent}
          onCancel={() => setReturnReady(false)}
          onConfirm={() => returnToVolunteer()}
          confirmText="RETURN"
          confirmLoading={returning}
          confirmLoadingText="RETURNING"
          className="w-11/12 lg:w-auto"
        />
      ) : null}

      {unapproveReady ? (
        <PopUpConfirmation
          noCancel={sendMessageOnAction}
          noConfirm={sendMessageOnAction}
          title="Confirm Case Return (Unapprove)"
          // content="Are you sure you want to return this case to the volunteer without awarding points?"
          content={unapproveFormContent}
          onCancel={() => {
            console.log('unapprove cancel');
            return setUnapproveReady(false);
          }}
          onConfirm={() => returnToVolunteer()}
          confirmText="RETURN"
          confirmLoading={returning}
          confirmLoadingText="RETURNING"
          className="w-11/12 lg:w-auto"
        />
      ) : null}

      <div className="relative flex flex-col flex-1 w-full lg:my-8">
        {saving && (
          <div className="fixed top-0 right-0 hidden p-2 mt-24 lg:block">
            <Spinner />
          </div>
        )}
        <Container width="lg:w-3/4" margin="m-3 lg:m-auto" className="relative px-3 py-4 lg:px-6 lg:py-8">
          <div className="p-2 font-bold">
            <h1 className="text-xl lg:text-4xl text-pursuit-gray">{title}</h1>
            <h2 className="text-sm text-pursuit-red lg:text-xl">{getCaseState()}</h2>
          </div>
          <div className="lg:relative">
            <div className="flex items-center mt-4 ml-2">
              <span className="text-pursuit-gray">Auto Save</span>
              <Switch
                onChange={autoSaveToggle}
                checked={autoSaveEnabled}
                className="ml-2 mr-4"
                height={20}
                width={40}
              />
              {/* {messageButton} */}
            </div>

            <div className="flex m-auto mt-2 mb-4 lg:hidden">
              <MobileDropdown
                className="w-full h-10 px-2 bg-white shadow"
                optionsArray={tabNames}
                selected={tabNames[formNumber]}
                selectionCallback={formName => saveAndTo(tabNames.indexOf(formName))}
              />
            </div>
            <div className="absolute bottom-0 right-0 flex-row hidden pr-4 mt-4 mb-2 lg: lg:flex">
              {personalInfoTab}
              <span className="ml-6">{sourcesTab}</span>
              <span className="ml-6">{reviewTab}</span>
              <span className="ml-6">{reportTab}</span>
            </div>
          </div>
          <div className="w-full px-2 py-4 my-2 bg-gray-100 lg:px-8 lg:py-8">
            {form}
            {!showAddStepForm && (
              <div className="flex flex-col-reverse justify-between mt-8 text-xs lg:flex-row">
                <div className="w-full mt-4 lg:mt-0 lg:w-auto">{prevButton}</div>
                {!returnFormNumber ? nextButton : saveEditButton}
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default CaseReview;
