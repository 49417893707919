import React, { useState, useEffect } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import * as Icons from 'react-icons/fa';
import ReactPlayer from 'react-player';

import { updateUser } from '../generated/graphql/mutations';

import {
  AuthHeader,
  PopUpConfirmation,
  RulesAndGuidelines,
  PrivateRoute,
  AlertHandler,
  LoggedInHeader,
  NavBar,
  Footer,
  Page,
  Spinner,
} from '../components';

import { RequiredTraining, CertificateUpload, CaseDownload, ThankYou, TermsOfUse, Privacy } from '../pages/public';
import { Settings, KnowledgeBase, Leaderboard } from '../pages/private';
import { Login, CreateAccount, ForgotPassword, Welcome } from '../pages/auth';
import { useUser } from '../contexts/userContext';

import VolunteerRouting from './volunteerRouting';
import EmployeeRouting from './employeeRouting';
import AdminRouting from './adminRouting';

import { UserGroups } from '../constants';

const USE_VOLUNTEER_VIEW = 'USE_VOLUNTEER_VIEW';
const RULES_KEY = 'rules_v1';

const AppRouter = () => {
  const { user, isLoading, setUser, userGroups, isCertified, isAdmin, isEmployee } = useUser();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [agreedToRules, setAgreedToRules] = useState(false);
  const [showVideoClose, setShowVideoClose] = useState(false);

  const isCertifiedOrEmployee = isCertified || isEmployee;

  const checkForVolunteerView = () => {
    const useVolunteerSavedPref = localStorage.getItem(USE_VOLUNTEER_VIEW);

    if (useVolunteerSavedPref && useVolunteerSavedPref.toLowerCase() === 'true') {
      return true;
    }

    return false;
  };

  const [useVolunteerView, setUseVolunteerView] = useState(checkForVolunteerView());

  const updateVolunteerView = enabled => {
    setUseVolunteerView(enabled);

    localStorage.setItem(USE_VOLUNTEER_VIEW, enabled.toString());
  };

  const userId = user?.id;

  useEffect(() => {
    if (!isCertified && !isEmployee) {
      return;
    }

    const seenRules = localStorage.getItem(RULES_KEY);

    // Admins and Employees should not be auto prompted with rules/guidelines
    if (!seenRules && !isEmployee) {
      setShowRules(true);
    } else if (isEmployee) {
      setShowRules(false);
    } else {
      setAgreedToRules(true);
    }
  }, [isCertified, isEmployee, userId]);

  const useEmployeeRouting = isEmployee && !useVolunteerView;

  const employeeGroups = [UserGroups.Employee, UserGroups.Admin];
  const allGroups = [UserGroups.Certified, ...employeeGroups];

  const completedTraining = Array.isArray(userGroups) && userGroups.some(x => allGroups.includes(x));

  const onFinishedIntroVideo = async () => {
    setUser({ ...user, watchedIntroVideo: true });

    try {
      await API.graphql(graphqlOperation(updateUser, { input: { watchedIntroVideo: true } }));
    } catch (error) {
      console.error('Error saving video progress: ', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        <Spinner className="h-24 w-24 text-pursuit-red" />
      </div>
    );
  }

  if (!user && !isLoading) {
    return (
      <div className="flex flex-col min-h-screen overflow-y-hidden">
        <div className="flex flex-col min-h-screen">
          <BrowserRouter>
            <AuthHeader />

            <Page>
              <AlertHandler />

              <Switch>
                <Route exact path="/">
                  <Welcome />
                </Route>

                <Route exact path="/login">
                  <Login />
                </Route>

                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>

                <Route exact path="/create-account">
                  <CreateAccount />
                </Route>

                <Route exact path="/case-download">
                  <CaseDownload />
                </Route>

                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Page>
          </BrowserRouter>
        </div>
      </div>
    );
  }

  if (user && !isLoading && userGroups) {
    return (
      <div className="flex flex-col min-h-screen overflow-y-hidden">
        <BrowserRouter>
          <LoggedInHeader
            menuLeft={
              completedTraining ? (
                <div className="flex items-center justify-center w-16 h-12 bg-white cursor-pointer text-pursuit-darkred lg:h-24">
                  <Icons.FaBars onClick={() => setSidebarOpen(!sidebarOpen)} size={25} />
                </div>
              ) : (
                <div />
              )
            }
            setUseVolunteerView={updateVolunteerView}
            useVolunteerView={useVolunteerView}
            isEmployee={isEmployee}
          />

          <div className="flex flex-row flex-1">
            <NavBar
              open={sidebarOpen}
              setOpen={setSidebarOpen}
              hidden={!completedTraining}
              showRules={() => setShowRules(true)}
              showIntroVideo={() => setShowVideo(true)}
              useEmployeeRouting={useEmployeeRouting}
              isAdmin={isAdmin}
            />

            {showRules && isCertifiedOrEmployee && (
              <PopUpConfirmation
                title="Project 1591® - Rules and Guidelines"
                content={<RulesAndGuidelines />}
                onConfirm={() => {
                  setAgreedToRules(true);
                  setShowRules(false);

                  localStorage.setItem(RULES_KEY, 'true');
                }}
                confirmText={!agreedToRules ? 'AGREE' : 'DISMISS'}
                noCancel
                className="w-11/12 overflow-y-auto lg:w-auto"
                contentWrapper="h-88 lg:h-128 overflow-y-auto"
                forceScrollBottom={!agreedToRules}
              />
            )}

            {(!showRules && isCertified && !isEmployee && !user.watchedIntroVideo) || (!showRules && showVideo) ? (
              <PopUpConfirmation
                title="How To Use PROJECT 1591®"
                content={
                  <>
                    <ReactPlayer
                      url="https://pursuit-public-assets.s3.us-west-2.amazonaws.com/tutorial_video.mp4"
                      width="1000px"
                      height="600px"
                      className="hidden w-full mt-4 bg-black lg:block"
                      playsinline
                      playing
                      controls={showVideoClose || user.watchedIntroVideo || isEmployee}
                      onEnded={() => setShowVideoClose(true)}
                      light
                    />

                    <ReactPlayer
                      url="https://pursuit-public-assets.s3.us-west-2.amazonaws.com/tutorial_video.mp4"
                      width="100%"
                      height="250px"
                      className="w-full mt-4 bg-black lg:hidden"
                      playsinline
                      playing
                      controls={showVideoClose || user.watchedIntroVideo || isEmployee}
                      onEnded={() => setShowVideoClose(true)}
                      light
                    />

                    {!showVideoClose && !user.watchedIntroVideo && !isEmployee && (
                      <p className="mt-4 text-pursuit-gray">
                        You must watch the intro video in full before continuing.
                      </p>
                    )}
                  </>
                }
                onConfirm={() => {
                  if (!user.watchedIntroVideo) {
                    onFinishedIntroVideo();
                  }

                  setShowVideo(false);
                }}
                noCancel
                noConfirm={!showVideoClose && !user.watchedIntroVideo && !isEmployee}
                confirmText="DISMISS"
                className="w-11/12 lg:w-auto h-10/12 lg:h-auto"
              />
            ) : null}
            <div className="flex flex-col flex-1 w-full">
              <Page>
                <AlertHandler />

                <Switch>
                  <Route path="/terms-of-use">
                    <TermsOfUse />
                  </Route>

                  <Route path="/privacy">
                    <Privacy />
                  </Route>

                  <Route path="/required-training">
                    {completedTraining ? <Redirect to="/" /> : <RequiredTraining />}
                  </Route>

                  <Route path="/certificate-upload">
                    {completedTraining ? <Redirect to="/" /> : <CertificateUpload />}
                  </Route>

                  <Route path="/thank-you">{completedTraining ? <Redirect to="/" /> : <ThankYou />}</Route>

                  <Route path="/case-download">
                    <CaseDownload />
                  </Route>

                  <PrivateRoute path="/settings" allowedGroups={allGroups}>
                    <Settings />
                  </PrivateRoute>

                  <PrivateRoute path="/knowledge" allowedGroups={allGroups}>
                    <KnowledgeBase />
                  </PrivateRoute>

                  <PrivateRoute path="/leaderboard" allowedGroups={allGroups}>
                    <Leaderboard />
                  </PrivateRoute>

                  <PrivateRoute path="/admin" allowedGroups={[UserGroups.Admin]}>
                    <AdminRouting />
                  </PrivateRoute>

                  <PrivateRoute path="/" allowedGroups={useEmployeeRouting ? employeeGroups : allGroups}>
                    {useEmployeeRouting ? (
                      <EmployeeRouting />
                    ) : (
                      <VolunteerRouting
                        showVideo={showVideo}
                        showRules={showRules}
                        setShowRules={setShowRules}
                        setShowVideo={setShowVideo}
                      />
                    )}
                  </PrivateRoute>
                </Switch>
              </Page>

              <Footer />
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
};

export default AppRouter;
