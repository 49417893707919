import React, { useEffect, useState } from 'react';
import * as Icons from 'react-icons/fa';

import { generateName } from '../../helpers/createAccount/nameGenerator';

import ToolTip from '../ToolTip';
import DropDown from '../DropDown';
import TextField from '../TextField';
import Button from '../Button';

const AliasGenerator = ({ alias, setAlias, excludeHeader, clearedAt }) => {
  const [gender, setGender] = useState();
  const [startingLetter, setStartingLetter] = useState();

  useEffect(() => {
    if (!alias) {
      const startingAlias = generateName();

      setAlias(startingAlias);
    }
  }, [alias, setAlias]);

  useEffect(() => {
    if (clearedAt) {
      setGender(null);
      setStartingLetter(null);
    }
  }, [clearedAt]);

  useEffect(() => {
    setAlias(
      generateName(
        gender ? gender.value : null,
        !startingLetter || startingLetter.value === 'Any Letter' ? null : startingLetter.value
      )
    );
  }, [gender, startingLetter, setAlias]);

  return (
    <>
      {!excludeHeader && (
        <div className="flex items-center mb-4">
          <label className="font-bold text-lg text-pursuit-gray mr-2">Generate an Alias</label>
          <ToolTip
            tooltipText="We use an automated username/alias generator to protect the identities of our users from being disclosed on other platform applications."
            title="Why an Alias?"
            className="text-pursuit-gray"
          />
        </div>
      )}

      <div className="flex flex-row">
        <DropDown
          value={gender}
          onChange={value => setGender(value)}
          label="Gender of Name"
          containerClassName="mr-2 mb-4"
          options={[
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
          ]}
          key={`${gender}-gender-selection`}
        />

        <DropDown
          value={startingLetter}
          label="First Name Starts With"
          onChange={value => setStartingLetter(value)}
          options={[
            { value: 'Any Letter', label: 'Any Letter' },
            { value: 'A', label: 'A' },
            { value: 'B', label: 'B' },
            { value: 'C', label: 'C' },
            { value: 'D', label: 'D' },
            { value: 'E', label: 'E' },
            { value: 'F', label: 'F' },
            { value: 'G', label: 'G' },
            { value: 'H', label: 'H' },
            { value: 'I', label: 'I' },
            { value: 'J', label: 'J' },
            { value: 'K', label: 'K' },
            { value: 'L', label: 'L' },
            { value: 'M', label: 'M' },
            { value: 'N', label: 'N' },
            { value: 'O', label: 'O' },
            { value: 'P', label: 'P' },
            { value: 'Q', label: 'Q' },
            { value: 'R', label: 'R' },
            { value: 'S', label: 'S' },
            { value: 'T', label: 'T' },
            { value: 'U', label: 'U' },
            { value: 'V', label: 'V' },
            { value: 'W', label: 'W' },
            { value: 'X', label: 'X' },
            { value: 'Y', label: 'Y' },
            { value: 'Z', label: 'Z' },
          ]}
          containerClassName="ml-2 mb-4"
          key={`${startingLetter}-starts-with-selection`}
        />
      </div>

      <div className="flex flex-row items-end mb-4">
        <TextField value={alias} placeHolder="" readonly label={excludeHeader ? 'Alias' : null} required />

        <Button
          solidBlue
          className="w-12 ml-4 flex items-center justify-center"
          onClick={() => {
            setAlias(
              generateName(
                gender ? gender.value : null,
                !startingLetter || startingLetter.value === 'Any Letter' ? null : startingLetter.value
              )
            );
          }}
        >
          <Icons.FaSyncAlt />
        </Button>
      </div>
    </>
  );
};

export default AliasGenerator;
