import React, { useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import * as Icons from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { validatePageTwo } from '../../../helpers/createAccount/createAccountValidation.js';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';

import {
  AliasGenerator,
  HeaderText,
  Button,
  TextField,
  Container,
  DropDown,
  CountryStateZipPicker,
} from '../../../components';

const PageTwo = ({
  alias,
  setAlias,
  dateOfBirth,
  setDateOfBirth,
  location,
  setLocation,
  zip,
  setZip,
  agreeToTerms,
  setAgreeToTerms,
  pageChange,
  signUp,
  country,
  setCountry,
  heardAbout,
  setHeardAbout,
}) => {
  const [, setAlert] = useContext(NotificationContext);
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/create-account_step2' });
  }, []);

  const heardAboutOptions = [
    { value: 'Guardian Group member', label: 'Guardian Group member' },
    { value: 'Friend | Coworker', label: 'Friend | Coworker' },
    { value: 'Guardian Group website', label: 'Guardian Group website' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Conference', label: 'Conference' },
    {
      value: 'Online search of Open Source Intelligence (OSINT)',
      label: 'Online search of Open Source Intelligence (OSINT)',
    },
    {
      value: 'Online search of counter sex trafficking',
      label: 'Online search of counter sex trafficking',
    },
    { value: 'Other', label: 'Other' },
  ];

  return (
    <Container
      grayedBackground
      height="lg:min-h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 mt-3 md:mx-12 lg:mx-auto"
    >
      <center>
        <HeaderText noBold className="text-3xl lg:text-4xl">
          Create an Account
        </HeaderText>
      </center>
      <div className="mt-4">
        <AliasGenerator alias={alias} setAlias={setAlias} />

        <label className="mr-1 font-light light-primary-blue-text">
          <span>
            Date of Birth<span className="ml-1 text-pursuit-red">*</span>
          </span>
          <div className="flex items-center mt-1">
            <DatePicker
              selected={dateOfBirth}
              onChange={date => setDateOfBirth(date)}
              maxDate={new Date()}
              className="w-full h-10 px-2 textfield-bg-color"
              shouldCloseOnSelect
            />
            <Icons.FaCalendarDay size={30} className="ml-4 cursor-pointer" />
          </div>
        </label>

        <CountryStateZipPicker
          country={country}
          state={location}
          zip={zip}
          setCountry={setCountry}
          setState={setLocation}
          setZip={setZip}
        />

        <DropDown
          value={heardAboutOptions.find(x => x.value === heardAbout)}
          label="How Did You Hear About Us?"
          onChange={option => setHeardAbout(option.value)}
          options={heardAboutOptions}
          className="mb-4"
          containerClassName="mb-4"
          required
        />

        <div className="flex items-center justify-start mb-4">
          <TextField
            noRounded
            noFullFieldWidth
            fieldWidth="w-5"
            fieldHeight="h-5"
            width="w-5"
            type="checkbox"
            checked={agreeToTerms}
            onChange={e => {
              setAgreeToTerms(e.target.checked);
            }}
            className="mr-4 lg:mr-1"
          />
          <p className="mx-2 my-2 lg:my-0 primary-blue-text">
            I agree to the&nbsp;
            <Button
              anchorTag
              onClick={() => {
                pageChange('terms-of-use');
              }}
              className="h-auto"
              inline
            >
              Terms of Use
            </Button>
            &nbsp;&&nbsp;
            <Button
              anchorTag
              onClick={() => {
                pageChange('privacy-policy');
              }}
              className="h-auto"
              inline
            >
              Privacy Policy
            </Button>
          </p>
        </div>
        <div className="flex justify-between mt-4">
          <Button
            linedBlue
            className="w-32"
            onClick={() => {
              history.push('/');
            }}
          >
            CANCEL
          </Button>
          <Button
            solidBlue
            className="w-32"
            onClick={() => {
              if (validatePageTwo(setAlert, dateOfBirth, location, agreeToTerms, country, heardAbout, zip)) {
                signUp();
              }
            }}
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default PageTwo;
