import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Switch from 'react-switch';
import * as Icons from 'react-icons/fa';
import Button from '../Button';
import HeaderLogo from '../../assets/Images/guardian_group_logo.svg';
import { useUser } from '../../contexts/userContext';
import Notifications from '../Notifications';

const LoggedInHeader = ({ menuLeft, useVolunteerView, setUseVolunteerView, isEmployee }) => {
  const history = useHistory();

  const [dropdownIsActive, setDropdownIsActive] = useState(false);

  const { user, setUser } = useUser();
  const [username, setUsername] = useState('');

  const clickOutsideRef = useRef(null);

  const deactivevateDropDoneOnOutsideClick = event => {
    if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
      setDropdownIsActive(false);
    }
  };

  useEffect(() => {
    if (user && user.cognitoUser) {
      if (isEmployee && !useVolunteerView) {
        setUsername(`${user?.firstName} ${user?.lastName}`);
      } else {
        setUsername(user?.alias);
      }
    }

    document.addEventListener('mousedown', deactivevateDropDoneOnOutsideClick);

    return () => {
      document.removeEventListener('mousedown', deactivevateDropDoneOnOutsideClick);
    };
  }, [user, isEmployee, useVolunteerView, clickOutsideRef]);

  function toggleDropdown() {
    switch (dropdownIsActive) {
      case false:
        setDropdownIsActive(true);
        break;
      case true:
        setDropdownIsActive(false);
        break;
      default:
        setDropdownIsActive(false);
    }
  }

  function renderDropdown() {
    if (dropdownIsActive) {
      return (
        <div ref={clickOutsideRef} className="absolute right-0 mt-4 mr-4 rounded-md shadow-lg lg:overflow-visible">
          <div className="px-4 bg-white rounded-md shadow-xs">
            {isEmployee && (
              <div className="flex items-center py-4 border-b border-gray-300">
                <span className="font-semibold text-pursuit-gray">Volunteer View</span>
                <Switch
                  onChange={enabled => {
                    setUseVolunteerView(enabled);
                    history.push('/');
                  }}
                  checked={useVolunteerView}
                  className="ml-2"
                  height={20}
                  width={40}
                />
              </div>
            )}

            <div className="flex items-center py-4 ">
              <Button
                solidBlue
                className="block w-full px-4 py-2 text-sm leading-5"
                onClick={async () => {
                  await Auth.signOut();

                  setUser(null);

                  try {
                    localStorage.clear();
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                Sign Out
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <nav className="fixed z-30 flex flex-row items-center justify-center w-screen h-12 bg-white shadow-lg lg:h-24 lg:justify-start lg:shadow-none">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center">
          {menuLeft}
          <span className="hidden lg:inline-block">
            <Link to="/">
              <img className="h-10 py-1 lg:h-24 lg:my-2 lg:py-2 lg:ml-4" src={HeaderLogo} alt="Guardian Group Logo" />
            </Link>
          </span>
        </div>

        <span className="lg:hidden flex-1">
          <Link to="/">
            <img className="h-10 py-1 lg:h-24 lg:my-2 lg:py-2 lg:ml-4" src={HeaderLogo} alt="Guardian Group Logo" />
          </Link>
        </span>

        <div className="flex items-center">
          <Notifications />

          <div>
            <button onClick={toggleDropdown} type="button" className="flex flex-row items-center focus:outline-none">
              <p className="my-auto text-sm header-text-font lg:text-3xl">{username}</p>
              <Icons.FaCaretDown className="mx-1 lg:ml-2 lg:mr-8" />
            </button>
            {renderDropdown()}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default LoggedInHeader;
