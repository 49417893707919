import React, { useState, useEffect } from 'react';
import * as Icons from 'react-icons/fa';
import { readableFormat } from '../../helpers/caseForms/utilHelpers';
import EditIcon from '../../assets/Images/edit.svg';
import TrashIcon from '../../assets/Images/trash.svg';
import PopUpConfirmation from '../PopupConfirmation';
import Feedback from '../Feedback';
import { AwardClassificationOptions, AwardTypes, CaseStatus } from '../../constants';

const ConfirmationStep = ({
  data,
  index,
  numSteps,
  isLast,
  swapSteps,
  direction,
  editSource,
  deleteSource,
  expandAll,
  noSwaps,
  hideExpand,
  feedbackComments,
  status,
  awardClassificationOption,
  disableEdit,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [swapDirection] = useState(direction || 0);
  const [translation, setTranslation] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  useEffect(() => {
    if (swapDirection === 0) return;
    const className = swapDirection > 0 ? 'translate-y-28' : '-translate-y-28';

    setTranslation(className);
  }, [swapDirection]);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  const upArrow = (
    <button
      className="m-auto"
      onClick={() => {
        if (index >= 2) {
          swapSteps(index, -1);
        }
      }}
      type="button">
      <Icons.FaChevronUp size={16} className={index > 1 ? 'text-guardian-darkblue' : 'text-gray-200'} />
    </button>
  );

  const downArrow = (
    <button
      className="m-auto"
      onClick={() => {
        if (index !== 0 && !isLast) {
          swapSteps(index, 1);
        }
      }}
      type="button">
      <Icons.FaChevronDown size={16} className={index !== 0 && !isLast ? 'text-guardian-darkblue' : 'text-gray-200'} />
    </button>
  );

  const getAwardedPoints = awardData => {
    if (awardData.award === AwardTypes.VerficationSource) {
      if (awardClassificationOption === AwardClassificationOptions.Underage) {
        return 3;
      }

      return 1;
    }

    if (awardData.award === AwardTypes.AccountIdentified) {
      if (awardClassificationOption === AwardClassificationOptions.Underage) {
        return 5;
      }

      return 1;
    }

    return 0;
  };

  const getAwardColor = () => {
    if (!data.award) {
      return 'bg-white text-pursuit-gray';
    }

    if (data.award === 'NO_POINTS') {
      return 'bg-pursuit-red text-white';
    }

    return 'bg-green-500 text-white';
  };

  function phoneData(arr) {
    return arr.map((obj, i) => (
      <div key={`Field${i}`}>
        <p className="pt-1 font-normal ">
          <b>Phone {i + 1}</b>
        </p>
        <div className="pb-1 pl-2 font-normal ">
          <p>
            <b>Number: </b>
            {obj.number}
          </p>
          <p>
            <b>Type: </b>
            {obj.type}
          </p>
        </div>
      </div>
    ));
  }

  function uniqueData(obj) {
    return Object.entries(obj).map(([field, value], i) => {
      if (!value) {
        return <></>;
      }

      const readableField = readableFormat(field);

      return (
        <p key={`Unique${i}`} className="py-1 font-normal ">
          <b>{`${readableField}: `}</b>
          {value}
        </p>
      );
    });
  }

  function defaultData(field, value, i) {
    const formatFieldName = x => {
      if (x.toLowerCase() === 'url') {
        return 'URL';
      }

      return x ? x.charAt(0).toUpperCase() + x.slice(1) : '';
    };

    return (
      <p key={`Field${i}`} className="py-1 font-normal " style={{ overflowWrap: 'anywhere' }}>
        <b className="text-pursuit-gray">{`${formatFieldName(field)}: `}</b>
        {value}
      </p>
    );
  }

  const expandArrow = expanded ? <Icons.FaChevronDown /> : <Icons.FaChevronRight />;
  const divHeight = expanded ? 'max-h-64r' : 'max-h-0';

  const renderStepContent = () => {
    if (!expanded) {
      return <div />;
    }

    const fields = [...Object.entries(data.body)];

    const idxOfDescription = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'description');

    // ensure description is the last shown field
    if (idxOfDescription !== -1) {
      const description = fields[idxOfDescription];

      fields.splice(idxOfDescription, 1);

      fields.push(description);
    }

    return fields.map(([field, value], i) => {
      if (field === 'website' || field === 'id') return null;

      if (field === 'phones') {
        return phoneData(value);
      }

      if (field === 'uniqueFields') {
        return uniqueData(value);
      }

      return defaultData(field, value, i);
    });
  };

  const renderFeedback = () => {
    if (!expanded) {
      return <></>;
    }

    return <Feedback comments={feedbackComments || []} stepId={data?.id} status={status} award={data?.award || null} />;
  };

  const stepContent = (
    <div key={`Data${index}`} className={` pt-4 px-12 transition-maxheight ease-in-out duration-700 ${divHeight}`}>
      {renderStepContent()}
      {renderFeedback()}
    </div>
  );

  const editButton = (
    <button
      className="w-10 focus:outline-none"
      onClick={() => {
        editSource(index);
      }}
      type="button">
      <img src={EditIcon} alt="Edit" className="w-5" />
    </button>
  );

  const deleteButton =
    index === 0 ? (
      <div className="w-10" />
    ) : (
      <button
        className="w-10 focus:outline-none"
        onClick={() => {
          setShowDelete(true);
        }}
        type="button">
        <img src={TrashIcon} alt="Trash" className="w-5" />
      </button>
    );

  return (
    <>
      <div
        key={`Step${index}`}
        className={`mb-4 p-2 pb-8 shadow font-bold transition-transform duration-500 transform ${translation}`}>
        <p className="m-2 mt-0 text-pursuit-darkred">Step {index + 1}</p>
        <div className="flex flex-row">
          <div className="flex flex-col w-12">
            {!noSwaps && index !== 0 && numSteps > 2 ? (
              <>
                {upArrow}
                {downArrow}
              </>
            ) : null}
          </div>
          <div className="flex flex-row justify-between w-full p-2 mr-4 text-white bg-guardian-darkblue">
            <p>{data.title}</p>
            <div className="flex">
              {(status === CaseStatus.Approved || status === CaseStatus.Resubmitted) && (
                <div className={`${getAwardColor()} h-6 w-8 rounded-full flex justify-center items-center p-0 mr-4`}>
                  {getAwardedPoints(data, awardClassificationOption)}
                </div>
              )}
              <button onClick={toggleExpanded} type="button" className="focus:outline-none">
                {!hideExpand && expandArrow}
              </button>
            </div>
          </div>
          {editSource && !disableEdit && editButton}
          {deleteSource && !disableEdit && deleteButton}
        </div>
        {stepContent}
      </div>
      {showDelete ? (
        <PopUpConfirmation
          title="Delete Step"
          content={`Are you sure you want to delete ${data?.body?.website || 'this step'}?`}
          onConfirm={() => {
            deleteSource(index);
            setShowDelete(false);
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
          confirmText="DELETE"
          destructive
          className="w-11/12 lg:w-auto"
        />
      ) : null}
    </>
  );
};

export default ConfirmationStep;
