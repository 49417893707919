export function personalInfoValidator(setAlert, caseState) {
  const firstNameEmpty = !caseState.firstName;
  const lastNameEmpty = !caseState.lastName;
  const ageEmpty = !caseState.age;
  const cityEmpty = !caseState.city;
  const stateEmpty = !caseState.state;

  const requiredFields = [];

  if (firstNameEmpty) {
    requiredFields.push('First name is required');
  }
  if (lastNameEmpty) {
    requiredFields.push('Last name is required');
  }
  if (ageEmpty) {
    requiredFields.push('Age is required');
  }
  if (cityEmpty) {
    requiredFields.push('City is required');
  }
  if (stateEmpty) {
    requiredFields.push('State is required');
  }

  if (requiredFields.length !== 0) {
    const message = `${requiredFields.join(', ')} (Personal Information)`;

    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message,
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !firstNameEmpty && !lastNameEmpty && !ageEmpty && !cityEmpty && !stateEmpty;
}

export function escortAdValidator(setAlert, escortAdState) {
  if (!escortAdState) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Escort Ad Incomplete',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  const urlEmpty = !escortAdState.url;
  const noPhones =
    !escortAdState.phones || !escortAdState.phones.length || escortAdState.phones.every(obj => !obj.number);

  let invalidPhone = false;

  if (escortAdState.phones && escortAdState.phones.length) {
    escortAdState.phones.forEach(obj => {
      if (!obj.number || !obj.type) {
        invalidPhone = true;
      } else {
        const phone = obj.number.replace('-', '');

        if (phone.length < 10) {
          invalidPhone = true;
        }
      }
    });
  }

  const requiredFields = [];

  if (urlEmpty) {
    requiredFields.push('URL cannot be empty');
  }
  if (noPhones) {
    requiredFields.push('Escort Ad requires at least one phone');
  }
  if (invalidPhone) {
    requiredFields.push('Phone number must have exactly 10 digits');
  }

  if (requiredFields.length !== 0) {
    const message = `${requiredFields.join(', ')} (Escort Ad)`;

    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message,
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !urlEmpty && !noPhones && !invalidPhone;
}
