/**
 * Step
 * @typedef {Object} Step
 * @property {string} id - UID of the step
 * @property {string} content - The content of the step
 */
/**
 * Source
 * @typedef {Step} Source
 * @property {string} id - UID of the step
 * @property {string} content - The content of the step
 */
/**
 * Staff Source
 * @typedef {Source} StaffSource
 * @property {string} previousSourceId - UID of the parent source
 */
/**
 * Compile a array of steps from an input sources, recursively adding parents
 *
 * @param {Step[]} steps - Array of steps
 * @param {Source[]} webSources - Array of web sources
 * @param {StaffSource[]} staffSources - Array of staff sources
 * @returns {Step[]} - Array of compiled steps
 * @example
 * const steps        = [ { body: 'Content A', id: 1 } ];
 * const webSources   = [ { body: 'Content B', id: 2 }, { body: 'Content C', id: 3 }  ];
 * const staffSources = [
 * { body: 'Content D', id: 4, previousSourceId: 1 },
 * { body: 'Content E', id: 5, previousSourceId: 4 }
 * ];
 * compileSteps(steps, webSources, staffSources) = [
 * { body:'Content A', id: 1 },
 * { body: 'Content D', id: 4 },
 * { body: 'Content E', id: 5 },
 * { body: 'Content B', id: 2 },
 * { body: 'Content C', id: 3 }
 * ];
 */
export const compileSteps = (steps, webSources, staffSources) => {
  const compiledSteps = steps.concat(webSources);

  // webSources.forEach(source => {
  //   compiledSteps.push(source);
  // });

  staffSources.forEach(source => {
    // const parentSource = compiledSteps.find(step => step.id === source.previousSourceId);
    const parentIndex = compiledSteps.findIndex(({ id }) => id === source.previousSourceId);

    if (parentIndex >= 0) {
      compiledSteps.splice(parentIndex + 1, 0, source);
    } else {
      // If the parent source is not found, add it to the end of the list
      // compiledSteps.push(source);
    }
  });

  // Do it again for looped items, lame
  staffSources.forEach(source => {
    if (compiledSteps.includes(source)) {
      return;
    }
    // const parentSource = compiledSteps.find(step => step.id === source.previousSourceId);
    const parentIndex = compiledSteps.findIndex(({ id }) => id === source.previousSourceId);

    if (parentIndex >= 0) {
      compiledSteps.splice(parentIndex + 1, 0, source);
    } else {
      // If the parent source is not found, add it to the end of the list
      compiledSteps.push(source);
    }
  });

  return compiledSteps;
};
