import React, { useState, useEffect } from 'react';
import ConfirmationStep from '../ConfirmationStep';
import Button from '../Button';
import { ESCORT_AD, CaseStatus, AwardTypes } from '../../constants';
import PointBreakdown from '../PointBreakdown';
import InvestigatorNotes from '../InvestigatorNotes';

const OrderStepsForm = ({ caseState, setCaseState, editSource, addButton, disableEdit, save }) => {
  const [expandAll, setExpandAll] = useState(false);
  const [awardClassificationOption, setAwardClassificationOption] = useState('');

  useEffect(() => {
    setAwardClassificationOption(caseState.awardClassificationOption);
  }, [caseState.awardClassificationOption]);

  function setInput(key, value) {
    setCaseState({ ...caseState, [key]: value });
  }

  const toggleExpandAll = () => setExpandAll(!expandAll);

  const swapSteps = (index, direction) => {
    const performSwap = (arr, idx, dir) => {
      const temp = arr[idx + dir];

      arr[idx + dir] = arr[idx];
      arr[idx] = temp;

      return arr;
    };

    const updatedWebSources = performSwap([...caseState.webSources], index - 1, direction); // escort ad is idx 0, need to adjust

    setTimeout(() => {
      setCaseState({ ...caseState, webSources: updatedWebSources });
    }, 100);
  };

  const deleteSource = index => {
    const sources = [...caseState.webSources];
    const staffSources = caseState.staffSources ? [...caseState.staffSources] : [];

    sources.splice(index - 1, 1);
    const webSourceIds = sources.map(x => x.id);
    const staffSourceIds = staffSources.map(x => x.id);
    const newAwardedPoints = (caseState.awardedPoints || []).filter(
      x => x.stepId === 'ESCORT_AD' || webSourceIds.includes(x.stepId) || staffSourceIds.includes(x.stepId)
    );
    const newCaseState = { ...caseState, webSources: [...sources], awardedPoints: [...newAwardedPoints] };

    // setCaseState({ ...caseState, webSources: [...sources] });
    // console.log({ newCaseState, newAwardedPoints });
    setCaseState(newCaseState);
  };
  const setNotes = notes => {
    // console.log('setNotes', note);
    // const data = { ...caseState, investigatorNotes: notes };

    // console.log('setNotes', data);
    // setInput('investigatorNotes', notes);
    setCaseState({ ...caseState, investigatorNotes: notes });
    // save({data});
  };

  const renderSteps = () => {
    const awards = caseState.awardedPoints ? [...caseState.awardedPoints] : [];

    const steps = [
      {
        title: 'Escort Ad',
        body: caseState.escortAd,
        id: ESCORT_AD,
        award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      },
    ];

    caseState.webSources.forEach(source => {
      steps.push({
        title: source.website ? source.website : '[no source type selected]',
        body: source,
        id: source.id,
        award: awards.find(x => x.stepId === source.id)?.awardType || null,
      });
    });

    const getIsApproved = idx => {
      let isApproved = false;

      if (idx > 0) {
        const currStepAward =
          caseState?.awardedPoints?.find(source => source.stepId === caseState.webSources[idx - 1].id) || null;

        if (
          caseState.status === CaseStatus.Approved &&
          (currStepAward?.awardType === AwardTypes.AccountIdentified ||
            currStepAward?.awardType === AwardTypes.VerficationSource)
        ) {
          isApproved = true;
        }
      } else if (caseState.status === CaseStatus.Approved) {
        isApproved = true;
      }

      return isApproved;
    };

    return steps.map((data, idx) => (
      <ConfirmationStep
        key={`Source_${data.body.id}`}
        data={data}
        index={idx}
        numSteps={steps.length}
        isLast={idx === steps.length - 1}
        swapSteps={swapSteps}
        editSource={editSource}
        deleteSource={deleteSource}
        expandAll={expandAll}
        feedbackComments={caseState?.feedbackComments || []}
        status={caseState?.status}
        awardClassificationOption={awardClassificationOption}
        disableEdit={disableEdit || getIsApproved(idx)}
      />
    ));
  };

  return (
    <>
      <div className="mb-8">
        {caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted ? (
          <>
            <h2 className="text-lg font-bold text-pursuit-gray">Review Approved Case</h2>
            <div className="text-sm text-pursuit-gray">
              <p>
                Please review all the steps you took to create this investigation. Use the up and down arrows below to
                rearrange the steps in the order of your discovery process. Edit and delete sources that weren't awarded
                points and add additional sources. Approved sources cannot be edited.
              </p>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-lg font-bold text-pursuit-gray">Review and Adjust</h2>
            <div className="text-sm text-pursuit-gray">
              <p>
                Please review all the steps you took to create this investigation. Use the up and down arrows below to
                rearrange the steps in the order of your discovery process.
              </p>
            </div>
          </>
        )}
      </div>
      <div className="py-4 mb-4">
        <h2 className="mb-2 text-lg font-bold text-pursuit-gray">Personal Details</h2>
        <ul>
          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Name: </b>
              {`${caseState.firstName || ''} ${caseState.middleName || ''} ${caseState.lastName || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Age: </b>
              {caseState.age}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Location: </b>
              {`${caseState.city || ''}, ${caseState.state || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Comments: </b>
              {caseState.details}
            </p>
          </li>
        </ul>
      </div>

      {(caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted) && (
        <div className="mb-8">
          <PointBreakdown
            expandAll
            noSwaps
            feedbackComments={caseState?.feedbackComments || []}
            status={caseState?.status}
            awardClassificationOption={awardClassificationOption}
            caseRecord={caseState}
          />
        </div>
      )}
      <div className="flex float-right">
        {addButton}
        {caseState.webSources.length <= 0 ? (
          <></>
        ) : (
          <>
            <Button solidBlue className="w-32 my-2" onClick={toggleExpandAll}>
              {expandAll ? <>Collapse All</> : <>Expand All</>}
            </Button>
          </>
        )}
      </div>
      <div className="clear-both" />
      {renderSteps()}

      <div className="flex flex-col mt-8">
        {/* <label className="mb-2 text-lg font-bold text-pursuit-gray">Investigator Notes</label> */}

        {/* <textarea
          disabled={disableEdit}
          placeholder="Notes or comments"
          className="h-24 pl-1 mt-1 mb-2 shadow"
          value={caseState.investigatorNotes}
          onChange={event => setInput('investigatorNotes', event.target.value)}
        /> */}

        <InvestigatorNotes
          canEdit={!disableEdit}
          canSort={!disableEdit}
          canDelete={!disableEdit}
          notes={caseState.investigatorNotes}
          // newVisibility={null}
          setNotes={setNotes}
        />
      </div>
    </>
  );
};

export default OrderStepsForm;
