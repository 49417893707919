import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ReactPlayer from 'react-player';
import HeaderText from '../../../components/HeaderText';
import { Container } from '../../../components';
import Crowd from '../../../assets/Images/landing/crowd.png';
import ArrowDown from '../../../assets/Images/landing/arrow-down.png';
import ArrowLeft from '../../../assets/Images/landing/arrow-left.png';
import CloudComputer from '../../../assets/Images/landing/cloud-computer.png';
import Police from '../../../assets/Images/landing/police.png';
import Hexagons from '../../../assets/Images/landing/hexagons.png';
import GuardianGroupLogo from '../../../assets/Images/landing/Guardian-Group-Logo.png';
import useAnalyticsEventTracker from '../../../helpers/GoogleAnalytics/eventTracker';

const Welcome = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  const gaEventTracker = useAnalyticsEventTracker('Welcome Video');

  return (
    <Container
      grayedBackground
      height="lg:min-h-168"
      width="lg:w-4/5"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 mt-3 lg:mt-12 lg:mx-auto"
    >
      <center>
        <HeaderText noBold fontSize="text-3xl lg:text-4xl mb-6 lg:mb-8">
          Learn About Project 1591®
        </HeaderText>
      </center>

      <div className="-mx-4 lg:mx-0">
        <ReactPlayer
          url="https://pursuit-public-assets.s3.us-west-2.amazonaws.com/project1591_welcome.mp4"
          width="100%"
          height="100%"
          className="w-full bg-black"
          playsinline
          controls
          onStart={() => gaEventTracker('start-welcome-video')}
          onEnded={() => gaEventTracker('finish-welcome-video')}
        />
      </div>

      <div className="flex flex-col mt-6 lg:mt-16 lg:mx-20">
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="text-lg text-pursuit-gray lg:w-128">
            <p className="mb-4 text-3xl text-pursuit-darkred">
              Project 1591® <span className="text-guardian-darkblue">Overview</span>
            </p>
            The first ever <span className="font-semibold">crowdsourcing</span> process & platform that{' '}
            <span className="font-semibold">enables volunteers</span> to become force multipliers to Guardian Group's
            mission of <span className="font-semibold">illuminating child victims of sex trafficking</span> in the
            United States.
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={Crowd} alt="crowd of people" className="mt-16 lg:mt-0 " />
            <img src={ArrowDown} alt="down arrow" className="w-8 mt-8 lg:w-10" />
          </div>
        </div>

        <div className="flex flex-col-reverse items-center justify-between lg:flex-row">
          <img src={Police} alt="police" className="object-contain w-48 h-48" />

          <img src={ArrowDown} alt="left arrow" className="w-8 mt-8 lg:hidden" />
          <img src={ArrowLeft} alt="left arrow" className="hidden h-10 lg:block" />

          <img src={GuardianGroupLogo} alt="left arrow" className="object-contain w-48 h-48" />

          <img src={ArrowDown} alt="left arrow" className="w-8 mt-8 lg:hidden" />
          <img src={ArrowLeft} alt="left arrow" className="hidden h-10 lg:block" />

          <img src={CloudComputer} alt="police" className="object-contain w-48 h-48" />
        </div>

        <div className="flex flex-row justify-between mt-6 lg:mt-16">
          <div className="text-lg text-pursuit-gray">
            <p className="mb-4 text-3xl text-pursuit-darkred">
              Project 1591® <span className="text-guardian-darkblue">Process</span>
            </p>
            Crowdsource a community of <span className="font-semibold">Open-Source Intelligence (OSINT)</span>{' '}
            volunteers to apply their skills for good in{' '}
            <span className="font-semibold">identifying and illuminating</span> underage victims of sex trafficking
            <span className="font-semibold"> to law enforcement</span> in the USA.
          </div>
        </div>
        <div className="flex items-center justify-center mt-4 lg:m-16">
          <img src={Hexagons} alt="process overview" className="object-contain w-full lg:w-auto lg:max-w-144" />
        </div>
      </div>
    </Container>
  );
};

export default Welcome;
