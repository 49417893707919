import { webSourceFieldLabels, webSourceFieldPlaceholders } from './webSourceFields';

export function readableFormat(fieldName) {
  if (!fieldName) {
    return fieldName;
  }

  return (
    fieldName
      .split(/(?=[A-Z])/)
      .join(' ')
      // capitalize first letter of first word
      .replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1))
  );
}

export const webSourceFieldLabel = field => webSourceFieldLabels[field] || readableFormat(field);
export const webSourceFieldPlaceholder = field => webSourceFieldPlaceholders[field] || readableFormat(field);
